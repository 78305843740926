import React from 'react'
import PropTypes from "prop-types";
import {
    SET_CURRENT_HOMEWORK,
    UPDATE_HOMEWORK,
} from "../../redux/actions/homework-actions";

import {connect} from "react-redux";

import {
    CREATE_HOMEWORK_CONFIG,
    UPDATE_HOMEWORK_CONFIG,
} from "../../redux/actions/configurator-actions";
import Loading from "../Loading";

class SaveButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            buttonState: null
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.loadingCreateUpdateHomeworkConfig === true && this.props.loadingCreateUpdateHomeworkConfig === false){
            this.setState({
                buttonState: null
            })
        }
    }

    saveConfig = () => {

        this.setState({
            buttonState: 'loading'
        })

        let assignment = this.props.assignment.map((row) => {
            return row.map((element) => {
                if (element.isAnswer) {
                    return {
                        color: element.color,
                        isAnswer: element.isAnswer,
                        result: "",
                        studentAnswer: "",
                        text: element.style === 'TRUE-FALSE' ? element.text.toLowerCase() : element.text,
                        correct: element.correct ? element.correct : null,
                        style: element.style
                    }
                } else {
                    return element
                }
            })
        })

        let homework = {...this.props.currentHomework}

        let media = {}

        if(this.props.config.withMedia && this.props.config.isVideo) {
            media = {
                type: 'video',
                videoLink: this.props.config.videoLink,
                startTimeMin: this.props.config.startTimeMin || 0.0,
                startTimeSec: this.props.config.startTimeSec || 0.0,
                startTime: this.props.config.startTime || 0.0,
                endTimeMin: this.props.config.endTimeMin || 0.0,
                endTimeSec: this.props.config.endTimeSec || 0.0,
                endTime: this.props.config.endTime || 0.0,
            }
        }

        if(this.props.config.withMedia && this.props.config.isImage) {
            media = {
                type: 'image',
                image: this.props.config.image,
            }
        }

        if(this.props.config.type === 'quizlet') {
            media = {
                type: 'quizlet',
                quizletId: this.props.config.quizletId,
                quizletType: this.props.config.quizletType,
                quizletLink: this.props.config.quizletLink
            }
        } else {
            media.taskTitle = this.props.config.taskTitle
        }

        let config = {
            type: this.props.config.type,
            media: media,
            background: this.props.config.background === null || this.props.config.type === 'quizlet' ?
                { type: 'color', source: 'rgb(255, 255, 255)' } :
                this.props.config.background,
            assignment: assignment,
            editorRawData: this.props.editorRawData,
            homework: homework.id
        }

        if (this.props.config.isExist) {
            this.props.updateHomeworkConfig(config)
        } else {
            this.props.createHomeworkConfig(config)
        }

        if (this.props.currentHomework.title !== this.props.config.title) {
            homework.title = this.props.config.title
            this.props.setCurrentHomework(homework)
            this.props.updateHomework(homework)
        }

    }

    render() {

        let loading = this.props.loadingCreateUpdateHomeworkConfig

        return (
            <React.Fragment>
                <button
                    type="button"
                    onClick={this.saveConfig}
                    className={`btn btn-outline btn-lg`}
                >
                    {loading && this.state.buttonState && <Loading size="xs" />}
                    Save
                </button>
            </React.Fragment>
        );
    }
}

SaveButton.propTypes = {
    needToStore: PropTypes.bool.isRequired,
    loadingCreateUpdateHomeworkConfig: PropTypes.bool,
    currentHomework: PropTypes.object,
    title: PropTypes.string,
    assignment: PropTypes.array,
    config: PropTypes.object,
    editorRawData: PropTypes.object,
    updateHomework: PropTypes.func,
    updateHomeworkConfig: PropTypes.func,
    createHomeworkConfig: PropTypes.func,
    setCurrentHomework: PropTypes.func
}

const mapStateToProps = (state) => ({
    loadingCreateUpdateHomeworkConfig: state.config.loadingCreateUpdateHomeworkConfig,
    createUpdateHomeworkConfigError: state.config.createUpdateHomeworkConfigError,
    currentHomework: state.homework.currentHomework,
    title: state.homework.title,
    editorRawData: state.config.editorRawData,
    assignment: state.config.assignment,
    config: state.config.config,
})

const mapDispatchToProps = (dispatch) => ({
    updateHomework: (currentHomework) => dispatch({ type: UPDATE_HOMEWORK, payload: currentHomework }),
    setCurrentHomework: (currentHomework) => dispatch({ type: SET_CURRENT_HOMEWORK, payload: currentHomework }),
    updateHomeworkConfig: (config) => dispatch({ type: UPDATE_HOMEWORK_CONFIG, payload: config }),
    createHomeworkConfig: (config) => dispatch({ type: CREATE_HOMEWORK_CONFIG, payload: config }),
})

export default connect(mapStateToProps, mapDispatchToProps)(SaveButton)
